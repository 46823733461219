<template>
  <div class="event-single" v-show="!isLoading">
    <!-- Hero Section -->
    <section class="hero is-primary is-bold">
      <div class="hero-body">
        <div class="container has-text-centered">
          <h1 class="title is-size-1-touch">{{ event.name }}</h1>
          <h2 class="subtitle">
            <div class="date" v-if="event.date">
              <strong>Date:</strong> {{ event.date }}
            </div>
            <div class="time" v-if="event.time" style="margin-top: 5px;">
              <strong>Time:</strong> {{ event.time }}
            </div>
          </h2>
        </div>
      </div>
    </section>

    <!-- Event Content Section -->
    <section class="section event-content">
      <div class="container">
        <div class="columns is-multiline">
          <!-- Event Image -->
          <div class="column is-full-mobile is-half-tablet is-half-desktop">
            <figure class="image is-4by3">
              <img :src="event.featuredImage" alt="Event image" id="eventImage" />
            </figure>
          </div>

          <!-- Event Details -->
          <div class="column is-full-mobile is-half-tablet is-half-desktop">
            <p class="is-size-6 description">{{ event.description }}</p>
            <p class="is-size-6" v-if="event.location">
              <strong>Location:</strong> {{ event.location }}
            </p>
            <!-- Social Media Links -->
            <div class="social-media">
              <a
                v-if="event.facebookLink"
                target="_blank"
                rel="noopener noreferrer"
                :href="event.facebookLink"
                class="icon-link"
              >
                <img src="../img/fb.png" alt="Facebook" />
              </a>
              <a
                v-if="event.websiteLink"
                target="_blank"
                rel="noopener noreferrer"
                :href="event.websiteLink"
                class="icon-link"
              >
                <img src="../img/web.png" alt="Website" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import EventService from "../services/eventService";

export default {
  name: "EventSingle",
  data() {
    return {
      isLoading: false,
      event: {}
    };
  },
  async mounted() {
    this.event = await EventService.get(this.$route.params.id);
  }
};
</script>

<style scoped>
/* Hero Section */
.hero-body {
  background: linear-gradient(to right, #93291e, #ed213a);
  margin-bottom: 0;
}

/* Image Styling */
#eventImage {
  border-radius: 10px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  width: 100%;
  height: auto;
  object-fit: cover;
}

/* Social Media Links */
.social-media {
  margin-top: 20px;
}

.social-media a {
  display: inline-block;
  margin-right: 10px;
}

.icon-link img {
  height: 40px;
  width: 40px;
  transition: transform 0.3s ease;
}

.icon-link img:hover {
  transform: scale(1.1);
}

/* Responsive Design */
@media screen and (max-width: 768px) {
  .hero-body {
    padding: 2rem 1.5rem;
  }

  .column img {
    width: 100%;
  }
}
</style>
